<h4 class="">{{ 'orderCost.orderSummary' | cxTranslate }}</h4>

<div class="cx-summary-partials" *ngIf="cart">
  <!-- Cart Header Details -->
  <div class="cx-summary-row d-flex justify-content-between">
    <div class="cx-summary-label">{{ 'cartCommon.label.requestedDeliveryDate' | cxTranslate }}:</div>
    <div class="cx-summary-amount">{{ cart?.requestedDeliveryDate }}</div>
  </div>
  <div class="cx-summary-row d-flex justify-content-between">
    <div class="cx-summary-label">{{ 'cartCommon.label.customerPO' | cxTranslate }}:</div>
    <div class="cx-summary-amount">{{ cart?.purchaseOrderNumber }}</div>
  </div>
  <div class="cx-summary-row d-flex justify-content-between">
    <div class="cx-summary-label">{{ 'cartCommon.label.jobNameNumber' | cxTranslate }}:</div>
    <div class="cx-summary-amount">{{ cart?.jobName }}</div>
  </div>
  <div class="cx-summary-row d-flex justify-content-between">
    <div class="cx-summary-label">{{ 'cartCommon.label.headerNotes' | cxTranslate }}:</div>
    <div class="cx-summary-amount">{{ cart?.headerComments }}</div>
  </div>
  <div class="cx-summary-row d-flex justify-content-between">
    <div class="cx-summary-label">{{ 'cartCommon.label.shipComplete' | cxTranslate }}:</div>
    <div class="cx-summary-amount">{{ cart?.shipComplete ? 'Yes' : 'No' }}</div>
  </div>

  <div *ngIf="cart?.paymentType" class="w-100 border-top mt-2 mb-2"></div>

  <!-- Checkout Steps -->
  <div *ngIf="cart?.paymentType" class="cx-summary-row cx-paymentType">
    <div class="cx-summary-label">
      {{ 'paymentTypes.title' | cxTranslate }}
    </div>
  </div>
  <div *ngIf="paymentType$ | async as paymentType; else defaultPayment" class="cx-summary-row">
    {{ getPaymentType(paymentType) | cxTranslate }}
  </div>
  <ng-template #defaultPayment>
    <div class="cx-summary-row">
      {{ getPaymentType(cart?.paymentType?.code) | cxTranslate }}
    </div>
  </ng-template>

  <ng-container *ngIf="deliveryAddress$ | async as deliveryAddress">
    <ng-container *ngIf="deliveryAddress && deliveryAddress.firstName">
      <div class="w-100 border-top mt-2 mb-2"></div>
      <div class="cx-summary-row">
        <div class="cx-review-summary-card cx-review-card-address">
          <cx-card [content]="getShippingAddressCard(deliveryAddress) | async"></cx-card>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="deliveryMode$ | async" class="w-100 border-top mt-2 mb-2"></div>

  <div *ngIf="deliveryMode$ | async as deliveryMode" class="cx-summary-row cx-review-card-shipping">
    <div class="cx-review-card-shipping">
      <div class="delivery-details">
        {{ 'paymentTypes.deliveryDetails' | cxTranslate }}
      </div>
      <div class="cx-summary-label delivery-mode">
        {{ 'paymentTypes.deliveryMode' | cxTranslate }}
      </div>
      <div class="delivery-method-value">
        {{ selectedDeleiveryMode?.name }}
      </div>
      <div *ngIf="cart?.additionalAccountNumber" class="cx-summary-label delivery-account">
        {{ 'paymentTypes.shippingAccountNumber' | cxTranslate }}
      </div>
      <div class="delivery-account-value">
        {{ cart?.additionalAccountNumber }}
      </div>
      <div *ngIf="cart?.shippingNotes" class="cx-summary-label delivery-notes">
        {{ 'paymentTypes.shippingNotes' | cxTranslate }}
      </div>
      <div class="delivery-notes-value">
        {{ cart?.shippingNotes }}
      </div>
    </div>
  </div>

  <div class="w-100 border-top mt-2 mb-2"></div>

  <!-- Cart Totals -->
  <!-- <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.subtotal' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.subTotal?.formattedValue }}
    </div>
  </div> -->
  <!-- <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{ (cart.deliveryCost?.formattedValue ? 'orderCost.shipping' : 'orderCost.estimatedShipping') | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{
        cart.deliveryCost?.formattedValue
          ? cart.deliveryCost.formattedValue
          : ('orderCost.toBeDetermined' | cxTranslate)
      }}
    </div>
  </div> -->
  <!-- <div class="cx-summary-row" *ngIf="cart.net; else cartWithoutNet">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.salesTax' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalTax?.formattedValue }}
    </div>
  </div> -->
  <div class="cx-summary-row cx-summary-total d-flex justify-content-between">
    <div class="cx-summary-label">
      {{ 'orderCost.total' | cxTranslate }}
    </div>
    <div class="cx-summary-amount">
      {{ cart.totalPrice?.formattedValue }}
    </div>
  </div>
  <!-- <div class="cx-summary-row" *ngIf="cart.totalDiscounts?.value > 0">
    {{ 'orderCost.discount' | cxTranslate }}
    {{ cart.totalDiscounts?.formattedValue }}
  </div> -->
  <!-- <ng-template #cartWithoutNet>
    <div class="cx-summary-row">
      {{
        cart.totalPriceWithTax?.value !== cart.totalPrice?.value
          ? ('orderCost.grossTax' | cxTranslate)
          : ('orderCost.grossIncludeTax' | cxTranslate)
      }}
      {{ cart.totalTax?.formattedValue }}.
    </div>
  </ng-template> -->

  <div class="w-100 border-top mt-2 mb-2"></div>

  <!-- CART ITEM SECTION -->
  <!-- <ng-container *ngIf="cart$ | async as cart">
    <div class="cx-review-cart-total d-none d-lg-block d-xl-block">
      {{ 'cartItems.cartTotal' | cxTranslate: { count: cart.deliveryItemsQuantity } }}:
      {{ cart.totalPrice?.formattedValue }}
    </div>
    <h4 class="cx-review-cart-heading d-block d-lg-none d-xl-none">
      {{ 'checkoutReview.placeOrder' | cxTranslate }}
    </h4>
    <div class="cx-review-cart-item col-md-12" *ngIf="entries$ | async as entries">
      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <cx-promotions [promotions]="orderPromotions"></cx-promotions>
      </ng-container>

      <cx-cart-item-list
        [items]="entries"
        [readonly]="true"
        [promotionLocation]="promotionLocation"
      ></cx-cart-item-list>
    </div>
  </ng-container> -->
</div>
