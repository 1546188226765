import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { GlobalMessageService, TranslationService, UserAddressService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { SloanAddress } from 'src/app/models/sloan/sloan-order.model';
import { KnBrAddressService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-address.service';
import { KnBrCheckoutDeliveryAddressComponent } from 'src/feature-libs/kn-br-checkout/base/components/kn-br-checkout-delivery-address/kn-br-checkout-delivery-address.component';
import { SloanAddressFormComponent } from '../../sloan-order/sloan-customer360/sloan-address-book/sloan-address-form/sloan-address-form.component';
import { KnBrCheckoutStepService } from 'src/feature-libs/kn-br-checkout/base/components/services/kn-br-checkout-step.service';

@Component({
  selector: 'cx-shipping-address',
  templateUrl: './sloan-shipping-address.component.html',
  styleUrls: ['./sloan-shipping-address.component.scss'],
})
export class SloanShippingAddressComponent extends KnBrCheckoutDeliveryAddressComponent implements OnInit, OnDestroy {
  isAccountPayment: any;
  constructor(
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected modalService: NgbModal,
    protected knBrAddressService: KnBrAddressService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: KnBrCheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected globalMessageService: GlobalMessageService
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      modalService,
      knBrAddressService,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    // super.ngOnDestroy();
  }

  get getOtshAddress(): any {
    let OtshAddress = {};
    this.checkoutDeliveryAddressFacade.getDeliveryAddressState().subscribe((address) => {
      OtshAddress['address'] = address;
      OtshAddress['card'] = this.getCardContent(address?.data, { id: address?.data?.id }, '', '', 'Selected');
    });
    return OtshAddress;
  }

  getCardContent(
    address: SloanAddress,
    selected: any,
    textDefaultShippingAddress: string,
    textShipToThisAddress: string,
    textSelected: string
  ): Card {
    let region = '';
    if (address?.region && address?.region?.isocode) {
      region = address?.region?.isocode;
    }

    return {
      title: address?.defaultAddress ? textDefaultShippingAddress : '',
      textBold: address?.firstName + (address?.lastName ? ' ' + address?.lastName : ''),
      text: [
        (address?.line2 ? address?.line2 + ' ' : '') + address?.line1,
        address?.department,
        address?.town + ', ' + region,
        address?.country?.isocode + ', ' + address?.postalCode,
        address?.phone,
      ],
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected?.id === address?.id ? textSelected : '',
    };
  }

  openAddressForm() {
    let selectedAddress = {};
    selectedAddress['editAddress'] = false;
    // this.knBrAddressService.setSelectedAddress(selectedAddress);
    let modalInstance: any;
    const modalRef = this.modalService.open(SloanAddressFormComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.isCheckOut = true;
    modalInstance.showCancelBtn = true;
    modalRef.result
      .then((address) => {
        if (address && address.isOneTime) {
          const onetimeaddress = {
            ...address.address,
            isDisposable: true,
          };
          this.knBrAddressService.createOneTimeAddress(onetimeaddress);
        } else {
          this.addAddress(address.address);
        }
      })
      .catch((error) => { });
  }
}
